import React, { FunctionComponent } from 'react'
import { verticals } from '@wh/common/chapter/types/verticals'
import { AdvertSummaryComponent } from '@bbx/search-journey/common/components/AdvertSummary/AdvertSummary'
import { getSeoUrl } from '@bbx/search-journey/common/lib/getSeoUrl'
import { AdvertSummary } from '@bbx/common/types/ad-detail/AdvertSummary'

interface AutoMotorAdvertSummaryComponentProps {
    advertSummary: AdvertSummary
    onClickTaggingEvent?: () => Promise<void> | void
}

export const AutoMotorAdvertSummaryComponent: FunctionComponent<AutoMotorAdvertSummaryComponentProps> = ({
    advertSummary,
    onClickTaggingEvent,
}) => {
    return (
        <AdvertSummaryComponent
            attributes={advertSummary.attributes.attribute}
            key={advertSummary.id}
            adTypeId={advertSummary.adTypeId}
            description={advertSummary.description}
            advertImage={advertSummary.advertImageList.advertImage?.[0]?.mainImageUrl}
            advertStatus={advertSummary.advertStatus}
            href={getSeoUrl(advertSummary)}
            teaserAttributes={advertSummary.teaserAttributes}
            vertical={verticals.MOTOR}
            onClickTaggingEvent={onClickTaggingEvent}
            adId={advertSummary.id}
            showBadges={true}
        />
    )
}
